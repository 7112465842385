import { WREATHS_FOOTER_UPDATE } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useWreathsFooterUpdate = () => {
  const { isLoading, variation } = useDecisionForFlag({
    name: WREATHS_FOOTER_UPDATE.EXPERIMENT_KEY,
    fallbackVariation: WREATHS_FOOTER_UPDATE.VARIATIONS.CONTROL,
  })

  return {
    isTreatment: variation === WREATHS_FOOTER_UPDATE.VARIATIONS.TREATMENT_1,
    isLoading,
  }
}
